<template>
  <v-container id="orders">
    <v-row>
      <v-col cols="12" sm="6" class="d-flex align-center">
        <h2 class="align-self-end page-title">{{ $t("orders.title") }}</h2>
      </v-col>
      <v-col cols="12" sm="6" v-if="!authEsa">
        <BaseSearch @search="setSearch" :label="`searchByTable`" />
      </v-col>
    </v-row>
    <OrdersFilter
      v-if="authEsa"
      :options="defaultOptions"
      :loading="loading"
      @filter="getOrdersByFilter"
      @search="setSearch"
    />
    <v-row class="mt-3">
      <v-col>
        <v-data-table
          :headers="headers"
          :header-props="{ sortByText: sortByText }"
          :items="orders"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
          :loading="loading"
          :mobile-breakpoint="600"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="cpId"
          show-expand
          class="elevation-1"
          :class="{ 'table-esa': authEsa }"
          locale="uk"
          :custom-filter="filterOrdersBySearch"
        >
          <v-progress-linear
            v-show="loading"
            color="privat"
            indeterminate
          ></v-progress-linear>
          <template v-slot:loading>
            <div
              class="d-flex flex-column justify-center align-center"
              style="min-height: 300px"
            >
              <span class="text-subtitle-1 pt-7 pb-5">
                {{ $t("load") }}
              </span>
            </div>
          </template>
          <template v-slot:no-data>
            <div
              class="d-flex flex-column justify-center align-center mb-6"
              style="min-height: 300px"
            >
              <span class="text-subtitle-1 pt-7 pb-5">
                {{ $t("orders.notOrders") }}
              </span>
              <v-btn
                v-if="!isOnlySupport"
                color="privat"
                class="white--text"
                width="265px"
                height="39px"
                @click="goToOffer"
              >
                {{ $t("action.createOffer") }}
              </v-btn>
            </div>
          </template>
          <template v-slot:item.program="{ item }">
            <span
              class="blue-grey darken-1 white--text d-flex justify-center align-center"
              style="line-height:1.2; padding:0 3px;border-radius: 2px;"
            >
              {{ item._program }}
            </span>
          </template>
          <template v-slot:item.crDt="{ item }">
            <span class="d-block">{{ item.crDt | date("numeric") }}</span>
            <span class="d-block text--secondary">
              {{ item.crDt | date("time") }}
            </span>
          </template>
          <template v-slot:item._seller="{ item }">
            <span class="d-block" v-if="item.orgOkpo && item.orgName">
              {{ item.orgOkpo }}
            </span>
            <span
              class="d-block text--secondary"
              v-if="item.orgOkpo && item.orgName"
            >
              {{ item.orgName }}
            </span>
            <span v-else>&mdash;</span>
          </template>
          <template v-slot:item._client="{ item }">
            <span class="d-block">{{ item.jurOkpo }}</span>
            <span class="d-block text--secondary">{{ item.jurName }}</span>
          </template>
          <template v-slot:item.cost="{ item }">
            <span>{{ item.cost | cost }}</span>
          </template>
          <template v-slot:item._periodType="{ item }">
            <span class="text-no-wrap">
              {{ item._periodType }}
            </span>
          </template>
          <template v-slot:item.pledgeQuantity="{ item }">
            <span>{{ item.pledgeQuantity }}</span>
          </template>
          <template v-slot:item._state="{ item }">
            <span>{{ item._state }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              id="showPdf"
              icon
              @click="clickActions(['showPdf', item])"
              :ripple="false"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="blue-grey darken-1">
                    mdi-file-pdf
                  </v-icon>
                </template>
                <span>{{ $t("action.show") }}</span>
              </v-tooltip>
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="px-3">
              <div class="item-block mt-1 mb-3 pa-3">
                <OrderInfo :item="item" />
                <OrderActions
                  :item="item"
                  :loading="loadBtn"
                  @clickActions="clickActions"
                />
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="orders.length" class="align-center">
      <v-col cols="4" sm="2" class="hidden-xs-only">
        <v-select
          :label="$i18n.t('label.onPage')"
          :value="itemsPerPage"
          :items="prePages"
          @change="itemsPerPage = parseInt($event, 10)"
          hide-details
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" :class="{ 'col-sm-9': !isAdmin, 'col-sm-8': isAdmin }">
        <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="5"
          color="privat"
        ></v-pagination>
      </v-col>
      <v-col
        sm="2"
        class="hidden-sm-and-up"
        :class="{ 'col-5': !isAdmin, 'col-4': isAdmin }"
      >
        <v-select
          :label="$i18n.t('label.onPage')"
          :value="itemsPerPage"
          :items="prePages"
          @change="itemsPerPage = parseInt($event, 10)"
          hide-details
          dense
        ></v-select>
      </v-col>
      <v-col cols="4" sm="2" offset="4" offset-sm="0" v-if="isAdmin">
        <v-btn
          id="downloadXLS"
          width="100%"
          elevation="1"
          :title="$t('action.downloadXLS')"
          @click="downloadXLS"
        >
          <v-icon color="grey darken-1" class="mr-1">
            mdi-download-outline
          </v-icon>
          <v-icon color="privat">mdi-microsoft-excel</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center" id="dialogs">
      <v-dialog
        v-model="showAttach"
        persistent
        min-width="320px"
        max-width="500px"
      >
        <v-card class="pa-3">
          <v-card-title class="text-h6 px-2 pt-2 pb-4 justify-center">
            {{ $t("offer.attachFile") }}
          </v-card-title>
          <v-card-text class="px-2 pb-0">
            <v-file-input
              v-model="specification"
              accept=".png, .jpg, .jpeg, .xls, .xlsx, .doc, .docx, .odt, .zip, .rar, .pdf"
              :rules="specification ? rules.spec : []"
              :label="$i18n.t('label.specFile')"
              :disabled="loading"
              ref="specification"
              color="privat"
              show-size
              outlined
              dense
              @change="withOutSpecFile = false"
            ></v-file-input>
            <span class="ml-8 mb-4 d-block">{{ $t("label.fileFormat") }}</span>
            <v-checkbox
              v-model="withOutSpecFile"
              :label="$i18n.t('label.withOutSpecFile')"
              :disabled="loading || !!specification"
              color="privat"
              class="mt-0"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions class="justify-space-between pt-0">
            <v-btn
              color="gray"
              @click="closeAttach"
              width="45%"
              aria-label="close"
              :disabled="loading"
            >
              {{ $t("action.close") }}
            </v-btn>
            <v-btn
              color="privat"
              :loading="loading"
              :disabled="!withOutSpecFile && !specification"
              @click="sendAttachFile"
              width="45%"
              aria-label="send file"
            >
              <span class="white--text">{{ $t("action.send") }}</span>
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  :size="22"
                  :width="2"
                  :value="75"
                  color="white"
                ></v-progress-circular>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showMeeting"
        persistent
        min-width="320px"
        max-width="500"
      >
        <v-card class="pa-3" min-height="250px">
          <v-card-title class="text-h6 px-2 pt-2 pb-4">
            {{ $t("offer.createMeeting") }}
          </v-card-title>
          <span class="px-2 pb-0 text--black" v-if="!loading">
            {{ $t("offer.addressMeeting") }}
          </span>
          <v-card-text class="px-2 pb-0" v-if="!loading">
            <BaseSelectAddress
              :validate="needValidate"
              :loading="loading"
              :size-full="true"
              :clear="toggleClearAddress"
              :load-region="toggleLoadRegion"
              @getAddrId="getAddressId"
              @clearAddrId="clearAddrId"
              @error="errorFromChild"
            />
            <v-divider class="mb-6"></v-divider>
            <v-select
              v-model="meeting.person"
              :items="possiblePeopleToMeet"
              :label="$t('label.meetingClient')"
              :rules="rules.meetingPerson"
              color="privat"
              outlined
              dense
            >
              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span>{{ item.name }}</span>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-card-text>
          <v-card-text
            class="d-flex justify-space-between px-2 pb-1 meeting-pikers"
            v-if="!loading"
          >
            <v-dialog
              ref="datePicker"
              v-model="showMeetingDatePiker"
              :close-on-content-click="false"
              max-width="290px"
              min-width="290px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMoment"
                  :label="$i18n.t('label.meetingDate')"
                  :rules="rules.meetingDate"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearDate"
                ></v-text-field>
              </template>
              <v-date-picker
                id="filterDate"
                v-model="meetingDate"
                :min="minDate"
                :locale="langDatePiker"
                :first-day-of-week="1"
                @change="closeDatePiker($event)"
                color="privat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="clearDate">
                  {{ $t("action.cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.datePicker.save(meetingDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-dialog
              ref="meetingTime"
              v-model="showMeetingTimePiker"
              :close-on-content-click="false"
              max-width="290px"
              min-width="290px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="meetingTime"
                  :label="$i18n.t('label.meetingTime')"
                  :rules="rules.meetingDate"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearTime"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="meetingTime"
                @change="closeTimePiker"
                format="24hr"
                color="privat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="clearTime">
                  {{ $t("action.cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.meetingTime.save(meetingTime)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-card-text>
          <v-card-text class="px-2 pb-1">
            <v-checkbox
              v-model="withOutMeeting"
              id="withOutSpecFile"
              :label="$i18n.t('label.withOutMeeting')"
              :disabled="loading"
              color="privat"
              class="mt-0"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions class="justify-space-between pt-0" v-if="!loading">
            <v-btn
              id="closeMeeting"
              color="gray"
              @click="closeMeeting"
              width="45%"
              aria-label="close"
              :disabled="loading"
            >
              {{ $t("action.close") }}
            </v-btn>
            <v-btn
              id="sendMeeting"
              color="privat"
              :loading="loading"
              :disabled="disabledSendMeeting"
              @click="sendMeeting"
              width="45%"
              aria-label="send file"
            >
              <span class="white--text">{{ $t("action.send") }}</span>
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  :size="22"
                  :width="2"
                  :value="75"
                  color="white"
                ></v-progress-circular>
              </template>
            </v-btn>
          </v-card-actions>
          <v-progress-circular
            v-if="loading"
            :size="35"
            color="privat"
            indeterminate
          ></v-progress-circular>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showPdf"
        fullscreen
        hide-overlay
        scrollable
        transition="dialog-bottom-transition"
      >
        <v-card :class="{ 'd-flex align-center justify-center': !srcPdf }">
          <v-progress-circular
            v-if="loading"
            indeterminate
            width="4"
            size="50"
            color="privat"
          ></v-progress-circular>
          <v-toolbar dark color="privat" v-if="srcPdf">
            <v-btn icon dark @click="closePdf">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ $t("orders.title_pdf") }}
            </v-toolbar-title>
          </v-toolbar>
          <iframe
            v-if="srcPdf"
            :src="srcPdf"
            width="100%"
            height="100%"
            id="iframePdf"
          ></iframe>
          <v-alert
            v-if="showPdf && !loading && !srcPdf"
            dense
            outlined
            type="error"
            max-width="100%"
          >
            {{ $t("error.other") }} {{ $t("error.loadPdf") }}
            <v-btn icon small class="error--text ml-5" @click="closePdf">
              <v-icon size="20">mdi-close</v-icon>
            </v-btn>
          </v-alert>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showHistory"
        min-width="320"
        max-width="500"
        persistent
      >
        <v-card class="pa-3">
          <v-card-title
            class="text-body-1 text-sm-h6 px-2 py-2 font-weight-bold d-flex justify-space-between align-baseline"
          >
            <span>
              Історія змін заявки
              <b class="text-caption text-sm-body-2 font-weight-bold d-block">
                ID: {{ cpId }} <br />
                Ref: {{ journalRef }}
              </b>
            </span>
            <v-btn
              icon
              small
              @click="showHistory = false"
              :ripple="false"
              class="close-dialog mt-n2 mr-n2"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-timeline class="px-1 pt-0 mt-3" dense clipped>
            <v-timeline-item
              v-for="(item, i) in history"
              :key="i"
              class="pb-3 pt-1"
              :class="`dot--${item.view.toLowerCase()}`"
              :color="item.color"
              :icon="item.icon"
              small
            >
              <div class="d-flex flex-column">
                <span class="text--black">
                  {{ item.stateNameUa }}
                </span>
                <span class="text--secondary subtitle-2" v-if="item.dateEd">
                  {{ item.dateEd }}
                </span>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-dialog>
    </v-row>

    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </v-container>
</template>

<script>
import showSnack from "@/mixins/showSnack.mixins";
import { mapGetters } from "vuex";
import moment from "moment";
import CONST from "@/utils/constants";
import * as orderService from "@/services/orders.service";
import errorHandler from "@/utils/errorHandler";
import BaseSearch from "@/components/BaseSearch";
import OrderInfo from "@/components/orders/OrderInfo";
import OrderActions from "@/components/orders/OrderActions";
import OrdersFilter from "@/components/orders/OrdersFilter";
import {
  createDataForTableOrders,
  getById,
  updateOrder
} from "@/services/orders.service";
import { interactionDataAddress } from "@/services/settings.services";
import BaseSelectAddress from "@/components/BaseSelectAddress";
import { makeMeeting, needMeeting } from "@/services/offer.service";
import checkFileType from "@/utils/checkFileType";
import { abortController, reInitController } from "@/services/abort.services";

export default {
  name: "orders",
  mixins: [showSnack],
  components: {
    OrdersFilter,
    BaseSearch,
    OrderInfo,
    OrderActions,
    BaseSelectAddress
  },
  data() {
    return {
      loading: true,
      loadBtn: false,
      lifeToken: null,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      prePages: [10, 20, 50, 100, 200],
      expanded: [],
      orders: [],
      cpId: null,
      journalRef: null,
      showAttach: false,
      showMeeting: false,
      specification: null,
      withOutSpecFile: false,
      showPdf: false,
      srcPdf: "",
      filter: {
        cpType: "DEALER"
      },
      defaultOptions: {},
      showHistory: false,
      history: [],
      mapHistoryColors: {
        SUCCESS: "privat",
        CURRENT: "white",
        FUTURE: "white",
        REJECT: "error"
      },
      mapHistoryIcons: {
        SUCCESS: "mdi-check",
        CURRENT: "",
        FUTURE: "",
        REJECT: "mdi-close"
      },
      cpType: "DEALER",
      meeting: {
        addressId: null,
        date: null,
        person: null,
        time: null
      },
      needValidate: false,
      langDatePiker: null,
      minDate: moment().format("YYYY-MM-DD"),
      meetingDate: null,
      showMeetingDatePiker: false,
      meetingTime: null,
      showMeetingTimePiker: false,
      toggleClearAddress: false,
      toggleLoadRegion: false,
      possiblePeopleToMeet: [],
      withOutMeeting: false,
      isMeetRequired: false
    };
  },
  computed: {
    ...mapGetters([
      "getPointOfEntry",
      "getOrdersAll",
      "getUser",
      "getOrdersAndSettingsOptions",
      "getStatus",
      "getStatusOffer",
      "devEnv"
    ]),
    sortByText() {
      return this.$i18n.t("label.sortByText");
    },
    headers() {
      if (this.authEsa) {
        return orderService.setTableEsaHeaders.call(this);
      } else {
        return orderService.setTableHeaders.call(this);
      }
    },
    rules() {
      return {
        spec: [
          v =>
            v.size <= CONST.MAX_FILE_SIZE ||
            this.$i18n.t("rules.file.max_size"),
          v => checkFileType(v) || this.$i18n.t("rules.file.bad_type")
        ],
        meetingPerson: [v => !!v || this.$i18n.t("rules.required")],
        meetingDate: [v => !!v || this.$i18n.t("rules.required")],
        meetingTime: [v => !!v || this.$i18n.t("rules.required")]
      };
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    isAdmin() {
      return this.getUser?.userGrants?.includes("ADMINISTRATOR");
    },
    disabledSendMeeting() {
      if (this.withOutMeeting) {
        return false;
      }
      return !this.meeting.person || !this.meeting.date || !this.meeting.time;
    },
    computedDateFormattedMoment() {
      return this.meetingDate
        ? moment(this.meetingDate).format("DD.MM.YYYY")
        : null;
    },
    isOnlySupport() {
      const userGrants = this.getUser?.userGrants;

      return userGrants?.length === 1 && userGrants?.includes("SUPPORT_PB");
    }
  },
  methods: {
    filterOrdersBySearch(value, search, item) {
      return Object.values(item)
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase());
    },
    async getOrdersByFilter(filter) {
      if (Object.entries(filter).length) {
        this.filter = filter;
        await abortController();
        await reInitController();
        if (this.authEsa) {
          this.cpType = filter.cpType;
          this.getEsaOrders(filter);
        } else {
          this.getOrders();
        }
      }
    },
    setSearch(data) {
      this.search = data?.toLowerCase();
    },
    goToOffer() {
      this.$store.dispatch("setOfferStatus", "create");
      this.$router.push("/offer");
    },
    closePdf() {
      this.showPdf = false;
      this.srcPdf = "";
    },
    getNeedMeeting() {
      needMeeting(this.cpId)
        .then(res => {
          let data = res.data;

          if (data.isMeetRequiredCp) {
            this.isMeetRequired = true;
            this.possiblePeopleToMeet = data.links.map(el => {
              return {
                ...el,
                value: el.ekbId
              };
            });
            this.toggleLoadRegion = !this.toggleLoadRegion;
            this.showAttach = false;
            this.showMeeting = true;
            this.loading = false;
          } else {
            this.sendOfferToBank();
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          this.closeAttach();
          this.loading = false;
          console.log("catch getNeedMeeting");
        });
    },
    reMakeMeeting() {
      this.loadBtn = true;
      makeMeeting(this.cpId)
        .then(() => {
          this.showSnack("success", [this.$i18n.t(`success.reMakeMeeting`)]);
        })
        .catch(err => {
          let errorCode = errorHandler(err);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch reMakeMeeting");
        })
        .finally(() => {
          this.loadBtn = false;
        });
    },
    sendAttachFile() {
      if (!checkFileType(this.specification) && !this.withOutSpecFile) {
        return false;
      }
      this.loading = true;
      if (this.specification) {
        let formData = new FormData();

        formData.append("cpId", this.cpId);
        formData.append("file", this.specification);
        orderService
          .sendFile(formData, this.authEsa)
          .then(res => {
            this.showSnack("success", [
              this.$i18n.t("success.sendAttachFile", { mail: res.data })
            ]);
            if (this.authEsa) {
              this.getNeedMeeting();
            } else {
              this.sendOfferToBank();
            }
          })
          .catch(err => {
            let errorCode = errorHandler(err, "sendAttachFile");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            this.closeAttach();
            console.log("catch sendAttachFile");
          });
      }
      if (this.withOutSpecFile) {
        if (this.authEsa) {
          this.getNeedMeeting();
        } else {
          this.sendOfferToBank();
        }
      }
    },
    closeAttach() {
      this.showAttach = false;
      this.withOutSpecFile = false;
      this.specification = null;
    },
    sendOfferToBank() {
      let params = "";
      let meet = this.meeting;

      if (this.authEsa) {
        if (this.isMeetRequired) {
          if (this.withOutMeeting) {
            params = `cpId=${this.cpId}&meetSelect=false`;
          } else {
            params = `cpId=${this.cpId}&addrId=${meet.addressId}&ekbId=${meet.person}&meetTime=${meet.date} ${meet.time}&meetSelect=true`;
          }
        } else {
          params = `cpId=${this.cpId}`;
        }
      } else {
        params = `cpId=${this.cpId}`;
      }
      orderService
        .sendToBank(params, this.authEsa)
        .then(() => {
          this.showSnack("success", [this.$i18n.t("success.sendOfferToBank")]);
        })
        .catch(err => {
          let errorCode = errorHandler(err, "sendOfferToBank");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch sendOfferToBank");
        })
        .finally(() => {
          this.getOrderById(this.cpId);
          this.closeAttach();
          this.closeMeeting();
          this.loading = false;
        });
    },
    clearAddrId() {
      this.meeting.addressId = null;
    },
    errorFromChild(error) {
      this.showSnack("error", error);
    },
    getAddressId(modelAd) {
      let url = this.createUrlAddress(modelAd);

      this.isLoading = true;
      interactionDataAddress(url, "POST")
        .then(res => {
          this.meeting.addressId = res.data;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "other");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getAddressId");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createUrlAddress(modelAd) {
      let corpus = modelAd.corpus ? `&corpus=${modelAd.corpus}` : "";
      let flat = modelAd.flat ? `&flat=${modelAd.flat}` : "";
      let idStreet = modelAd.street.code;
      let building = modelAd.building.desc;

      return `esa/addr/updateWCheck?id=${idStreet}&house=${building}${corpus}${flat}`;
    },
    sendMeeting() {
      if (this.withOutMeeting) {
        this.loading = true;
        this.sendOfferToBank();
      } else {
        this.needValidate = !this.needValidate;
      }

      if (this.meeting.addressId && this.meeting.person) {
        this.loading = true;
        this.sendOfferToBank();
      }
    },
    closeMeeting() {
      this.showMeeting = false;
      this.withOutMeeting = false;
      this.meeting.addressId = null;
      this.toggleClearAddress = !this.toggleClearAddress;
      this.clearDate();
      this.clearTime();
    },
    clearDate() {
      this.meeting.date = null;
      this.meetingDate = null;
      this.showMeetingDatePiker = false;
    },
    closeDatePiker() {
      this.meeting.date = this.meetingDate;
    },
    clearTime() {
      this.meeting.time = null;
      this.meetingTime = null;
      this.showMeetingTimePiker = false;
    },
    closeTimePiker() {
      this.meeting.time = this.meetingTime;
    },
    getOrders() {
      if (this.devEnv) return;

      this.loading = true;
      this.$store
        .dispatch("getOrders")
        .then(() => {
          console.log("dispatch then getOrders", this.getOrdersAll);
          if (this.getOrdersAll.length) {
            this.orders = createDataForTableOrders.call(
              this,
              this.getOrdersAll,
              this.authEsa
            );
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "GET_DATA");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getOrders");
        })
        .finally(() => {
          this.loading = this.getStatus === "ERR_CANCELED";
        });
    },
    getOrderById(cpId) {
      getById(cpId, this.authEsa)
        .then(res => {
          let data = createDataForTableOrders.call(
            this,
            res.data,
            this.authEsa
          );

          this.orders = updateOrder(data, this.orders);
        })
        .catch(err => {
          let errorCode = errorHandler(err, "GET_DATA");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getOrderById");
        });
    },
    getPdfReport() {
      orderService
        .getPdf(this.cpId, this.authEsa)
        .then(res => {
          this.srcPdf = res.data;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "loadPdf");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getPdfReport");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clickActions(data) {
      let [action, item] = data;

      this.cpId = item.cpId;
      switch (action) {
        case "sendToBank":
          if (!this.specification) {
            this.showAttach = true;
          }
          if (!this.authEsa && this.specification) {
            this.sendOfferToBank();
          }
          if (this.authEsa && this.specification) {
            this.getNeedMeeting();
          }
          break;
        case "reMakeMeeting":
          this.reMakeMeeting();
          break;
        case "showPdf":
          this.loading = true;
          this.showPdf = true;
          this.localTimeout = setTimeout(() => {
            this.getPdfReport();
          }, 500);
          break;
        case "history":
          this.getHistory();
          this.journalRef = item.journalRef;
          break;
      }
    },
    getDefaultOptions() {
      this.$store
        .dispatch("getEsaOrdersDefaultOptions")
        .then(() => {
          let data = this.getOrdersAndSettingsOptions;

          if (data) {
            this.createOptions(data);
          } else {
            let errorCode = this.getStatus || "getDefaultOptions";

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("then getEsaOrdersDefaultOptions error - ", errorCode);
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getDefaultOptions");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getEsaOrdersDefaultOptions");
        });
    },
    createOptions(data) {
      this.defaultOptions = {
        companies: data.ogrDataFilterCps,
        states: data.cpStateForFindCp.map(el => {
          return {
            value: el,
            label: this.$i18n.t(`orders.state.${el}`)
          };
        }),
        subjects: data.allObjectDetails
      };
    },
    downloadXLS() {
      let filter = this.filter;
      let arrParams = [];
      let requestParams = "";
      let fileLink = document.getElementById("linkXLS");

      if (!fileLink) {
        fileLink = document.createElement("a");
        fileLink.setAttribute("id", "linkXLS");
      }

      for (const [key, value] of Object.entries(filter)) {
        if (filter[key] && key !== "storeIdList") {
          arrParams.push(`${key}=${value}`);
        }
        if (filter[key] && key === "storeIdList") {
          arrParams.push(`storeIdList=${value.join(",")}`);
        }
      }
      requestParams = arrParams.join("&");
      fileLink.href = `esa/excelCp?${requestParams}`;
      fileLink.setAttribute("download", "orders.xls");
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    getEsaOrders(data) {
      if (this.devEnv) return;

      this.loading = true;
      console.log("start getEsaOrders", new Date());
      this.$store
        .dispatch("getEsaOrders", { data: data })
        .then(() => {
          if (this.getOrdersAll.length) {
            this.orders = createDataForTableOrders.call(
              this,
              this.getOrdersAll,
              this.authEsa
            );
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "GET_DATA");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getEsaOrders");
        })
        .finally(() => {
          this.loading = this.getStatus === "ERR_CANCELED";
        });
    },
    getHistory() {
      this.loading = true;
      orderService
        .getHistory(this.cpId, this.authEsa)
        .then(res => {
          let data = res.data;

          this.history = data.map(el => {
            return {
              ...el,
              color: this.mapHistoryColors[`${el.view}`],
              icon: this.mapHistoryIcons[`${el.view}`]
            };
          });
          this.showHistory = true;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "GET_DATA");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getHistory");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    init() {
      this.getOrders();
    },
    initEsa() {
      this.getDefaultOptions();
      this.getEsaOrders({ cpType: "PB" });
    }
  },
  mounted() {
    if (this.authEsa) {
      this.initEsa();
    } else {
      this.init();
    }
    this.langDatePiker = CONST.LOCALE[this.$i18n.locale].toLowerCase();
    moment.locale(this.$i18n.locale);
  },
  beforeDestroy() {
    clearTimeout(this.localTimeout);
  }
};
</script>

<style lang="scss">
#orders {
  @media (min-width: 1264px) {
    max-width: 95%;
  }
  @media (min-width: 960px) {
    max-width: 95%;
  }
}
#app {
  .v-list-item__title {
    font-size: 15px;
  }
  .v-timeline--dense:not(.v-timeline--reverse)::before {
    left: calc(15px - 1px);
  }
  .theme--light.v-timeline::before {
    background: rgba(0, 0, 0, 0.15);
  }
  .v-timeline-item {
    position: relative;
    //&:first-child::before,
    &:last-child::before {
      content: "";
      position: absolute;
      background: #fff;
      width: 20px;
      height: 20px;
    }
    //&:first-child::before {
    //  top: -8px;
    //  left: 1px;
    //}
    &:last-child {
      padding-bottom: 8px !important;
      &::before {
        bottom: 0;
        left: 1px;
        height: 42px;
      }
    }
  }
  .v-timeline-item__body {
    max-width: calc(100% - 40px);
    min-height: 46px;
  }
  .v-timeline-item__divider {
    min-width: 40px;
    padding-top: 1px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .v-timeline-item__dot {
    background: rgb(0 0 0 / 25%);
    box-shadow: none;
    &::before,
    &::after {
      content: "";
      position: absolute;
      border-radius: 50%;
      z-index: -1;
    }
    &::before {
      background: #fff;
      left: calc(48% - 23px);
      width: 32px;
      height: 32px;
      top: -4px;
    }
    &::after {
      background: rgb(0 0 0 / 25%);
      height: 20px;
      left: calc(48% - 18px);
      width: 20px;
      top: 2px;
    }
    &--small .v-timeline-item__inner-dot {
      width: 16px;
      height: 16px;
    }
  }
  .dot--success,
  .dot--current {
    .v-timeline-item__dot {
      &::after {
        background: #75af26;
      }
    }
  }
  .dot--reject {
    .v-timeline-item__dot::after {
      background: #ff5252;
    }
    .v-icon {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .mdi-file-document::before {
    margin-left: 2px;
  }
}
</style>
